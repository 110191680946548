import AxiosInstance from "./axios"
import { BALANCE_HISTORY_OPTION_LAST_THREE_MONTHS } from "../shared/constants/analytics/dashboard"
import {
  buildAggregativeBalanceHistoryParams,
  buildTransactionHistoryParams,
  buildUsersParams,
} from "./endpointsHelper"

export const fetchCurrentUser = () => AxiosInstance.get("/v1/users/me")

export const fetchCurrentUserBankAccounts = (types = []) => {
  const params = new URLSearchParams()
  types.forEach((type) => {
    params.append("type", type)
  })
  return AxiosInstance.get(`/v1/users/me/accounts?${params}`)
}

/**
 * @deprecated
 * TODO: this should be removed when its usage been replaced by {@link fetchTerminalUsers}
 */
export const getTerminalUsers = ({ withDeleted, searchQuery }) => {
  const params = new URLSearchParams()
  params.set("withDeleted", withDeleted)
  if (searchQuery) params.set("searchQuery", searchQuery)

  return AxiosInstance.get(`/v1/company-users/me/terminals?${params}`)
}

export const fetchTerminalUsers = ({
  searchQuery,
  page = 0,
  size = 10,
  withBalance = false,
}) => {
  const params = new URLSearchParams()
  if (searchQuery) params.set("searchQuery", searchQuery)
  params.set("withBalance", withBalance)
  params.set("page", page)
  params.set("size", size)

  return AxiosInstance.get(`/v1/corporate-users/me/terminals?${params}`)
}

export const fetchBankAccountBalanceHistory = (
  accountId,
  timeFrame = BALANCE_HISTORY_OPTION_LAST_THREE_MONTHS
) => {
  const params = new URLSearchParams()
  params.append("timeFrame", timeFrame)
  return AxiosInstance.get(
    `/v1/users/me/accounts/${accountId}/balance-history?${params}`
  )
}

export const getAggregativeBalanceHistory = ({
  accountIds,
  currencies,
  startDate,
  endDate,
  timeFrame,
}) => {
  const params = buildAggregativeBalanceHistoryParams({
    accountIds,
    currencies,
    startDate,
    endDate,
    timeFrame,
  })

  return AxiosInstance.get(`/v1/users/me/accounts/balance-history?${params}`)
}

export const getAggregativeBalanceHistoryCSV = ({
  accountIds,
  currencies,
  startDate,
  endDate,
  timeFrame,
}) => {
  const params = buildAggregativeBalanceHistoryParams({
    accountIds,
    currencies,
    startDate,
    endDate,
    timeFrame,
  })

  return AxiosInstance.get(
    `/v1/users/me/accounts/balance-history/csv?${params}`,
    {
      responseType: "blob",
    }
  )
}

/**  @param {string} operationId */
export const getTransctionStatement = (operationId) =>
  AxiosInstance.get(`/v1/operations/${operationId}/statement/pdf`, {
    responseType: "blob",
  })

/**
 *
 * @typedef TransactionHistory
 * @property {string} id
 * @property {string} accountId
 * @property {CounterParty} counterparty
 * @property {import("../features/bankAccounts/bankAccounts-slice").MonetaryValue} monetaryValue
 * @property {import("./endpoints/transactions").TransactionType} transaction
 * @property {number} timestamp
 * @property {"PENDING" | "IN_PROGRESS" | "COMPLETED" | "OUTDATED" | "FUNDS_BLOCKED"} status
 *
 *
 * @typedef {object} GetTransactionHistoryResponse
 * @property {TransactionHistory[]} content
 * @property {boolean} first
 * @property {boolean} last
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 * @property {number} pageNumber
 *
 * @param {object} filterData
 * @param {number} filterData.page
 * @param {number} filterData.size
 * @param {string[]} filterData.selectedAccountIds
 * @param {RECEIVED | SENT | ALL} filterData.type
 * @param {object} filterData.dateRange
 * @param {date} filterData.dateRange.endDate
 * @param {date} filterData.dateRange.startDate
 * @param {boolean} filterData.dateRange.initial
 * @param {string} filterData.dateRange.key
 * @param {string} filterData.searchQuery
 * @param {import("./endpoints/transactions").TransactionType} filterData.operationTransactionTypes
 * @param {"PENDING" | "COMPLETED"  | "BLOCKED_FUNDS"} filterData.operationStatusGroup
 * @param {boolean} filterData.corporateTransfersOnly - this flag is only used to filter the transfer page(table and csv) to show correct data because of complexity the filtering is done on the Backend
 *
 * @returns {Promise<import("axios").AxiosResponse<GetTransactionHistoryResponse>>}
 */

export const getTransactionHistory = ({
  page,
  size,
  selectedAccountIds,
  type,
  dateRange,
  searchQuery,
  operationTransactionTypes,
  operationStatusGroup,
  corporateTransfersOnly,
}) => {
  const params = buildTransactionHistoryParams({
    page,
    size,
    selectedAccountIds,
    type,
    dateRange,
    searchQuery,
    operationTransactionTypes,
    operationStatusGroup,
    corporateTransfersOnly,
  })

  return AxiosInstance.get(`/v1/users/me/operations?${params}`)
}

/**
 *
 * @typedef {string} GetTransactionCSVResponse
 *
 * @param {object} filterData
 * @param {number} filterData.page
 * @param {number} filterData.size
 * @param {string[]} filterData.selectedAccountIds
 * @param {"RECEIVED" | "SENT" | "ALL"} filterData.type
 * @param {object} filterData.dateRange
 * @param {date} filterData.dateRange.endDate
 * @param {date} filterData.dateRange.startDate
 * @param {boolean} filterData.dateRange.initial
 * @param {string} filterData.dateRange.key
 * @param {import("./endpoints/transactions").TransactionType} filterData.operationTransactionTypes
 * @param {"PENDING" |  "COMPLETED" |  "BLOCKED_FUNDS"} filterData.operationStatusGroup
 * @param {boolean} filterData.corporateTransfersOnly - this flag is only used to filter the transfer page(table and csv) to show correct data because of complexity the filtering is done on the Backend
 *
 * @returns {Promise<import("axios").AxiosResponse<GetTransactionCSVResponse>>}
 */

export const getTransactionsCSV = ({
  page,
  size,
  selectedAccountIds,
  type,
  dateRange,
  operationTransactionTypes,
  operationStatusGroup,
  corporateTransfersOnly,
}) => {
  const params = buildTransactionHistoryParams({
    page,
    size,
    selectedAccountIds,
    type,
    dateRange,
    operationTransactionTypes,
    operationStatusGroup,
    corporateTransfersOnly,
  })

  return AxiosInstance.get(`/v1/users/me/operations/csv?${params}`, {
    responseType: "blob",
  })
}

/**
 * @typedef {object} CounterParty
 * @property { "BARBERSHOP" | "CAFE" | "EXCHANGE_OFFICE" | "FOOD"
 *  | "GAS_STATION" | "GROCERY" | "IT_SERVICES" | "KIOSK" | "OTHER"
 *  | "PERSONAL_CARE" | "PHARMACY" | "PHONE_STORE" | "SHOPPING" | "WORKSHOP"
 * } businessCategory
 * @property {string} iban
 * @property {string} iconUrl
 * @property {string} initials
 * @property {string} name
 * @property {string} nameTranslationKey
 * @property {string} phoneNumber
 *
 * @typedef {object} Fee
 * @property {number} amount
 * @property { import("../shared/constants/bank-accounts").CURRENCY_TYPE } currency
 *
 * @typedef {object} Transaction
 * @property {import("../features/bankAccounts/bankAccounts-slice").MonetaryValue} baseMonetaryValue
 * @property {string} gifId
 * @property {string} note
 * @property { "CARD_ATM_REVERSAL" | "CARD_DEPOSIT" | "CARD_ONLINE_PAYMENT" | "CARD_ORDER_FEE"
 * | "CARD_PAYMENT" | "CARD_PAYMENT_COMMISSION" | "CARD_RECURRING_FEE" | "CARD_WITHDRAWAL"
 * | "CARD_WITHDRAWAL_COMMISSION" | "COMMISSION_FOR_SALARY" | "CURRENCY_EXCHANGE" | "DELIVERY_DEPOSIT_FEE"
 * | "DELIVERY_WITHDRAWAL_FEE" | "DEPOSIT" | "DEPOSIT_AT_BRANCH" | "DOMESTIC_BANK_TRANSFER"
 * | "FAMILY_ACCOUNT_TRANSFER" | "FEES_BANK_TRANSFER" | "INCOMING_BANK_TRANSFER" | "INTERNATIONAL_BANK_TRANSFER"
 * | "INVITE_TRANSFER" | "INVITE_TRANSFER_FORWARD" | "INVITE_TRANSFER_RETURN" | "LOAN_GRANT" | "LOAN_REPAYMENT"
 * | "MONEY_BOX_TRANSFER" | "ONLINE_PAYMENT" | "OTHER" | "P2P" | "PAYMENT" | "PRO_MEMBERSHIP_RECURRING_FEE"
 * | "REMAINING_FUNDS_TRANSFER" | "SALARY" | "SERVICESTORE_PAYMENT" | "TERMINAL_TRANSFER" | "WITHDRAWAL"
 * | "WITHDRAWAL_AT_BRANCH" | "TOPUP" | "CARD_TRANSFER" | "CARD_TRANSFER_REFUND" | "ONLINE_PAYMENT_REFUND"
 * | "CARD_ORDER_FEE_REFUND" | "CASHBACK" | "FUNDS_BLOCKED" | "BUY_NOW_PAY_LATER_REPAYMENT" | "BANK_OPERATION"
 * } type
 *
 * @typedef {object} GetTransactionDetailsResponse
 * @property {CounterParty} counterparty
 * @property {Fee} fee
 * @property {string} id
 * @property {import("../features/bankAccounts/bankAccounts-slice").MonetaryValue} monetaryValue
 * @property {number} timestamp
 * @property {Transaction} transaction
 *
 * @param {string} transactionId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<GetTransactionDetailsResponse>>}
 */
export const getTransactionDetails = (
  transactionId,
  { operationStatusGroup },
  config
) =>
  AxiosInstance.get(`/v2/operations/${transactionId}`, {
    params: { operationStatusGroup },
    config,
  })

/**
 *
 * @param {object} data
 * @param {string} data.context
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<{oneTimeAuthorizationCode: string}>>}
 */
export const createAuthorizationCode = (data, config) =>
  AxiosInstance.post("/v1/users/me/authorization-codes", data, config)

export const checkAuthorizationCode = (authorizationCode) =>
  AxiosInstance.get(
    `/v1/users/me/authorization-codes/${authorizationCode}/status`
  )

/**
 * @typedef {object} CreateTerminalUserResponse
 * @property {string} id
 *
 * @param {object} data
 * @param {string} data.name
 * @param {string} [data.displayName]
 * @param {string} data.dialingCode
 * @param {string} data.phoneNumber
 * @param {string} data.password
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<CreateTerminalUserResponse>>}
 */
export const createTerminalUser = (
  { name, displayName = undefined, dialingCode, phoneNumber, password },
  config
) =>
  AxiosInstance.post(
    "/v1/company-users/me/terminals",
    {
      name,
      displayName,
      dialingCode,
      phoneNumber,
      password,
      // TODO: make accountCurrencies a parameter instead of hardcoded IQD when we add USD currency.
      accountCurrencies: ["IQD"],
    },
    config
  )

/**
 * @typedef {object} EditTerminalNameResponse
 * @property {string} id
 *
 * @param {object} input
 * @param {string} input.terminalId
 * @param {string} input.name
 * @param {string} [input.displayName]
 *
 * @returns {Promise<import("axios").AxiosResponse<EditTerminalNameResponse>>}
 */
export const editTerminalName = ({
  terminalId,
  name,
  displayName = undefined,
}) =>
  AxiosInstance.put(`/v1/company-users/me/terminals/${terminalId}/name`, {
    name,
    displayName,
  })

/**
 * @param {string} terminalId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<DeleteTerminalUserResponse>>}
 */

export const deleteTerminalUser = (terminalId, config) =>
  AxiosInstance.delete(`/v1/company-users/me/terminals/${terminalId}`, config)

/**
 * @typedef {object} HideTerminalUserResponse
 * @property {string} id
 *
 * @param {string} terminalId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<void>>}
 */
export const hideTerminalForAgents = (terminalId, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/terminals/${terminalId}/hide`,
    null,
    config
  )

/**
 * @param {string} terminalId
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Promise<import("axios").AxiosResponse<void>>}
 */
export const showTerminalForAgents = (terminalId, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/terminals/${terminalId}/unhide`,
    null,
    config
  )

/**
 * @param {object} data
 * @param {string} data.dialingCode
 * @param {string} data.phoneNumber
 * @param {string} data.userGroup
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns { Promise<import("axios").AxiosResponse<{exists: boolean}>> }
 */
export const checkPhoneNumberExistence = (
  { dialingCode, phoneNumber, userGroup },
  config
) =>
  AxiosInstance.post(
    "/v1/company-users/phone-numbers/check-existence",
    {
      dialingCode,
      phoneNumber,
      userGroup,
    },
    config
  )

export const getTerminalInfo = (terminalId) =>
  AxiosInstance.get(`/v1/company-users/me/terminals/${terminalId}`)

export const getTerminalTransferConfiguration = (sourceAccountId) =>
  AxiosInstance.get(
    `/v1/transactions/configurations/terminal-transfer?sourceAccount=${sourceAccountId}`
  )

export const changeTerminalPassword = ({ terminalId, password }, config) =>
  AxiosInstance.put(
    `/v1/company-users/me/terminals/${terminalId}/password`,
    {
      password,
    },
    config
  )

/**
 * @typedef {object} User
 * @property {string} id
 * @property {string} initials
 * @property {string} name
 * @property {string} phoneNumber
 * @property {string} status
 *
 * @typedef {object} GetUsersResponse
 * @property {User[]} content
 * @property {boolean} first
 * @property {boolean} last
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 * @property {number} pageNumber
 *
 * @param {string} page
 * @param {string} size
 *
 * @returns {Promise<import("axios").AxiosResponse<GetUsersResponse>>}
 */

export const getUsers = ({ page, size, withBlocked, searchQuery }) => {
  const params = buildUsersParams({ page, size, withBlocked, searchQuery })

  return AxiosInstance.get(`/v1/corporate-users/me/agents?${params}`)
}

// TODO backend should have another endpoint for fetching terminal
// transfer transactions like shift money and get back the name of terminals/main account
// for now we will use this endpoint to find the names
export const getAllBankAccounts = () => {
  const params = new URLSearchParams()
  params.set("type", "CURRENT")
  params.append("type", "SUBACCOUNT")
  params.set("withChildAccounts", true)

  return AxiosInstance.get(`/v1/users/me/accounts?${params}`)
}

/**
 * @param {string} url
 *
 * @returns {Promise<import("axios").AxiosResponse<Blob | MediaSource>>}
 */

export const fetchDocument = (url) =>
  AxiosInstance.get(url, {
    responseType: "blob",
  })
